@import "~@/erp/styles/variables/variables.scss";



















































.book-box {
  display: flex;
  height: 100%;
  & > div:first-child {
    width: 25%;
    min-width: 380px;
    height: 100%;
    flex-shrink: 0;
  }
  & > div:last-child {
    flex: 1;
    width: 0;
    height: 100%;
    .no-book {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background: #fff;
      border-left: 1px solid #ebeef5;
      position: relative;
      & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        img {
          width: 100px;
        }
        p {
          margin-top: 30px;
          color: #777777;
          font-size: 14px;
        }
      }
    }
  }
}
