@import "~@/erp/styles/variables/variables.scss";


























































































































































































































































































































.book-info {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background: #fff;
  border-left: 1px solid #ebeef5;
  overflow-y: auto;
  .info-item {
    & > div:first-child {
      line-height: 56px;
      border-bottom: 1px solid #ebeef5;
      font-size: 14px;
      font-family: 'PingFang Bold';
      color: #303030;
    }
    .info-detail {
      margin-top: 20px;
      line-height: 36px;
      font-size: 14px;
      padding: 0 36px;
      display: flex;
      & > span:first-child {
        color: #777777;
        width: 80px;
        text-align: left;
      }
      & > span:last-child {
        color: #303133;
        flex: 1;
        /deep/ .el-rate__icon {
          line-height: 36px !important;
        }
      }
      &.vertical {
        display: flex;
        flex-direction: column;
        span {
          width: 100%;
        }
      }
    }
    &.btns {
      margin-top: 20px;
    }
    .btn-group {
      margin-top: 30px;
    }
  }
}
.user-locked {
  width: 100%;
  text-align: center;
  padding-top: 100px;
  .icon {
    font-size: 60px;
    color: #e5e5e5;
  }
  .desc {
    font-size: 14px;
    color: #9999;
    line-height: 1.5;
  }
}
